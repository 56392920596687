import React, { useEffect, useState } from 'react';
import { getMyPage } from '../apis/mypage';
import { useLocation, useNavigate } from 'react-router-dom';

export const Mypage = () => {
  const [data, setData] = useState();
  const [loading, setLoading] = useState(true);
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const query = new URLSearchParams(location.search);
    const accessToken = query.get('accessToken');
    const refreshToken = query.get('refreshToken');
    if (accessToken && refreshToken) {
      localStorage.setItem('access', accessToken);
      localStorage.setItem('refresh', refreshToken);
    }

    getMyPage().then(res => {
      setData(res);
      setLoading(false);
    }).catch(error => {
      console.error('Failed to load my page', error);
    });
  }, [location]);

  const handleLogout = () => {
    // Clear local storage or cookies
    localStorage.removeItem('access');
    localStorage.removeItem('refresh');
    navigate('/'); // Redirect to home page or login page

  };

  if (loading) {
    return (
      <div>
        <div>Loading...</div>
        <button onClick={handleLogout}>Logout</button>
      </div>
    );
  }

  return (
    <div>
      <div>{data?.name}</div>
      <div>{data?.phone}</div>
      <div>{data?.email}</div>
      <button onClick={handleLogout}>Logout</button>
    </div>
  );
};
