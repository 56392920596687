import axios from 'axios';

export const getNewRefreshToken = async() =>{
    const accessToken = localStorage.getItem('access');
    const refreshToken = localStorage.getItem('refresh');

    const result = await axios.post(`{process.env.REACT_APP_PUBLIC_URL}:3001/api/refresh`,{
        refreshToken,
    },
    {
        headers :{
            Authorization : accessToken,
        }
    }
    );

    return result.data;
}