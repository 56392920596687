import axios from 'axios';
import { getNewRefreshToken } from './refresh';

export const getAuthAxios = (token) =>{
    const authAxios = axios.create({
        baseURL : `${process.env.REACT_APP_PUBLIC_URL}:3001`,
        headers : {
            Authorization : token,
        }
    })

    authAxios.interceptors.response.use((response) => response, async (error) => {
        if (error.response && error.response.status === 401) {
            const { accessToken, refreshToken } = await getNewRefreshToken();
            error.config.headers.Authorization = `Bearer ${accessToken}`; // 토큰 앞에 Bearer 추가 필요 (JWT 토큰 사용 시)
            localStorage.setItem('access', accessToken);
            localStorage.setItem('refresh', refreshToken);
            
            // authAxios를 통해 재요청
            return authAxios.request(error.config);
        }
    
        // 에러를 그대로 전파
        return Promise.reject(error);
    });

    return authAxios;
}