import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { getMembers } from '../apis/admin';

export const Admin = () => {
    const [members, setMembers] = useState([]);
    const [loading, setLoading] = useState(true);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(0);
    const [searchTerm, setSearchTerm] = useState(''); // 검색어 상태 정의
    const navigate = useNavigate();

    const fetchMembers = async (page, search = '') => { // 기본값 추가
        setLoading(true);
        try {
            const data = await getMembers(page, search);
            setMembers(data.data);
            setCurrentPage(data.currentPage);
            setTotalPages(data.totalPages);
            setLoading(false);
        } catch (error) {
            console.error('Failed to fetch members', error);
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchMembers(currentPage, searchTerm);
    }, [currentPage, searchTerm]); // searchTerm을 의존성 배열에 추가
    
    const handleLogout = () => {
        localStorage.clear();
        navigate('/');
    };

    const handleSearch = () => { // 검색 처리 함수
        fetchMembers(1, searchTerm);
    };

    const renderPageNumbers = () => {
        const pageNumbers = [];
        for (let i = 1; i <= Math.min(10, totalPages); i++) {
            pageNumbers.push(i);
        }
        return pageNumbers.map(number => (
            <button
                key={number}
                onClick={() => fetchMembers(number, searchTerm)}
                disabled={number === currentPage}
                style={{ margin: '0 2px' }}
            >
                {number}
            </button>
        ));
    };

    return (
        <div>
            {loading ? <div>Loading...</div> : (
                <>
                    <input
                        type="text"
                        placeholder="Search members..."
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)} // 입력 처리
                    />
                    <button onClick={handleSearch}>Search</button>
                    
                    <div>
                        {members.map(member => (
                            <div key={member.id}>{member.id} - {member.name} - {member.email}</div>
                        ))}
                    </div>
                    <div>
                        <button
                            onClick={() => fetchMembers(currentPage - 1, searchTerm)}
                            disabled={currentPage === 1}
                        >
                            이전
                        </button>
                        {renderPageNumbers()}
                        <button
                            onClick={() => fetchMembers(currentPage + 1, searchTerm)}
                            disabled={currentPage === totalPages || totalPages === 0}
                        >
                            다음
                        </button>
                    </div>
                    <button onClick={handleLogout}>Logout</button>
                </>
            )}
        </div>
    );
};

export default Admin;
