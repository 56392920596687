import { getAuthAxios } from './authAxios';

export const getMyPage = async () => {
    const access = localStorage.getItem('access');
    const authAxios = getAuthAxios(`Bearer ${access}`);
  
    try {
      const response = await authAxios.get('/mypage');
      return response.data;
    } catch (error) {
      console.error('Failed to fetch my page data:', error);
      // 여기서 더 상세한 에러 정보를 로그할 수 있습니다.
      console.error('Axios error details:', error.response);
      throw error; // 에러를 재발생시켜 상위 컴포넌트에서 처리할 수 있도록 함
    }
  }
  