import React, {useState, useEffect} from 'react'
import styled from 'styled-components'; // Make sure to import styled
import { Wrapper, Title, Form, Inputs, Input } from '../components/Common'; // Corrected import path and components
import { Link, useNavigate } from 'react-router-dom';
import { login } from '../apis/login';

export const Home = () => {
    const [id, setId] = useState('');
    const [pw, setPw] = useState('');

    //const router = useNavigate();
    const navigate = useNavigate();

    useEffect(() => {
        // Check if user is already logged in
        const accessToken = localStorage.getItem('access');
        const refreshToken = localStorage.getItem('refresh');
        
        if (accessToken && refreshToken) {
            navigate('/mypage'); // Redirect to Mypage if tokens exist
        }
    }, [navigate]);

    const onChangeId = (e) => {setId(e.target.value);};
    const onChangePw = (e) => {setPw(e.target.value);};

    const onClick = async () => {
        try {
            const result = await login(id, pw);
            localStorage.setItem('access', result.accessToken);
            localStorage.setItem('refresh', result.refreshToken);
            navigate('/mypage');
        } catch (error) {
            if (error.response && error.response.status === 401) {
                alert(error.response.data.message);
            } else {
                alert('An unexpected error occurred.');
            }
        }
    };

  return (
    <Wrapper>
        <Title>로그인하기</Title>
        <Form>
            <Inputs>
                <Input placeholder="아이디" value={id} onChange={onChangeId} />
                <Input placeholder="비밀번호" value={pw} type="password" onChange={onChangePw} />
            </Inputs>
            <Button onClick={onClick}>Login</Button>
        </Form>
        <StyledLink to="/signup">회원가입하기</StyledLink>
        <StyledAnchor href={`${process.env.REACT_APP_PUBLIC_URL}:3001/api/auth/kakao`}>카카오로 로그인하기</StyledAnchor>

    </Wrapper>
  )
}

export default Home;
const Button = styled.button`
background-color:black;
color:white;
padding:20px;
border-radius:10px;
`;


const StyledLink = styled(Link)`
    margin-top: 20px;
    color: black;
    text-decoration: none;
    &:visited {
        color: black;
        text-decoration: none;
    }
`;

const StyledAnchor = styled.a`
    margin-top: 20px;
    color: black;
    text-decoration: none;
    &:visited {
        color: black;
        text-decoration: none;
    }
`;