import React, {useEffect} from 'react'
import { Inputs, Title, Wrapper,Form, Input } from '../components/Common'
import { useForm } from '../hooks/useForm';
import styled from 'styled-components'; // Make sure to import styled
import { signUp } from '../apis/signUp';
import { useNavigate } from 'react-router-dom';

export const Signup = () => {
  const [id,onChangeId] = useForm();
  const [pw,onChangePw] = useForm();
  const [name,onChangeName] = useForm();
  const [phone,onChangePhone] = useForm();
  const [email,onChangeEmail] = useForm();

  const navigate = useNavigate();

  useEffect(() => {
    // Check if user is already logged in
    const accessToken = localStorage.getItem('access');
    const refreshToken = localStorage.getItem('refresh');
    
    if (accessToken && refreshToken) {
      navigate('/mypage'); // Redirect to Mypage if tokens exist
    }
}, [navigate]);

  // const onClick= async()=>{
  //   await signUp(id,pw,name,phone,email);
  //   navigate('/');
  // }

  const onClick = async () => {
    try {
        await signUp(id,pw,name,phone,email);
        navigate('/'); // Navigate to login page after successful signup
    } catch (error) {
        if (error.response && error.response.status === 409) {
            alert(error.response.data.message); // Show alert if there's an ID conflict
        } else {
            alert('An unexpected error occurred.'); // Show alert for other errors
        }
    }
  };

  
  return (
    <Wrapper>
      <Title>회원가입</Title>
      <Form>
        <Inputs>
          <Input value={id} onChange={onChangeId} placeholder = "아이디"></Input>
          <Input value={pw} onChange={onChangePw} placeholder = "비밀번호" type="password"></Input>
          <Input value={name} onChange={onChangeName} placeholder = "이름"></Input>
          <Input value={phone} onChange={onChangePhone} placeholder = "휴대폰번호"></Input>
          <Input value={email} onChange={onChangeEmail} placeholder = "이메일"></Input>
        </Inputs>
      </Form>
      <Button onClick={onClick}>Sign Up</Button>
    </Wrapper>
  )
}


export default Signup;

const Button = styled.button`
background-color:black;
color:white;
padding:20px;
border-radius:10px;
`;