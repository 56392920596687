import React, { useEffect } from 'react';
import { Title, Wrapper, Box } from '../components/Common';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const Share = () => {
  useEffect(() => {
    if (window.Kakao && !window.Kakao.isInitialized()) {
      window.Kakao.init('b3287305d66d8c6809e496b4397fa39e');
    }

    if (window.Kakao) {
      window.Kakao.Link.createDefaultButton({
        container: '#kakaotalk-sharing-btn',
        objectType: 'feed',
        content: {
          title: '미트하우스24 추천이벤트',
          description: '회원가입하고 추천하면 영화티켓과 각종 경품을 쏩니다.',
          imageUrl: `${process.env.REACT_APP_PUBLIC_URL}/assets/images/logo.jpg`,
          link: {
            mobileWebUrl: `${process.env.REACT_APP_PUBLIC_URL}`,
            webUrl: `${process.env.REACT_APP_PUBLIC_URL}`
          }
        },
        buttons: [
          {
            title: '회원가입',
            link: {
              mobileWebUrl: `${process.env.REACT_APP_PUBLIC_URL}`,
              webUrl: `${process.env.REACT_APP_PUBLIC_URL}`
            }
          },
          {
            title: '추천하기',
            link: {
              mobileWebUrl: `${process.env.REACT_APP_PUBLIC_URL}/share`,
              webUrl: `${process.env.REACT_APP_PUBLIC_URL}/share`
            }
          }
        ]
      });
    }
  }, []);

  const addChannel = () => {
    if (window.Kakao) {
      window.Kakao.Channel.addChannel({
        channelPublicId: '_vztqG'
      });
    }
  };

  return (
    <Wrapper>
      <Title>미트하우스24 이벤트</Title>
      <Box>추천받고 가입해주세요<p>가입하시는 고객님들은 추천을 통해 영화티켓을 보내드려요</p></Box>
      <StyledLink id="kakaotalk-sharing-btn" style={{ border: 'none', background: 'none' }}>
        주변인에게 미트하우스24를 추천해주세요
        {/* <img src="https://www.dlive.kr/html/images/front/ko/v45/visual_02_240112.jpg"></img> */}
      </StyledLink>
      <StyledAnchor href={`${process.env.REACT_APP_PUBLIC_URL}:3001/auth/kakao`}>
        카카오 회원가입을 통해 행사정보를 받아보세요
      </StyledAnchor>
      <StyledButton onClick={addChannel}>
        <img src="/tool/resource/static/img/button/channel/add/channel_add_small.png" alt="카카오톡 채널 추가하기 버튼" />
      </StyledButton>
    </Wrapper>
  );
};

const StyledLink = styled(Link)`
    margin-top: 20px;
    color: black;
    text-decoration: none;
    &:visited {
        color: black;
        text-decoration: none;
    }
`;

const StyledAnchor = styled.a`
    margin-top: 20px;
    color: black;
    text-decoration: none;
    &:visited {
        color: black;
        text-decoration: none;
    }
`;

const StyledButton = styled.button`
    margin-top: 20px;
    border: none;
    background: none;
    cursor: pointer;
    &:hover {
        opacity: 0.8;
    }
`;

export default Share;
