import axios from 'axios';

export const signUp = async(id,pw,name,phone,email) =>{

    const result = await axios.post(`${process.env.REACT_APP_PUBLIC_URL}:3001/api/signup`,{
        id,pw,name,phone,email
    }
    );

    return result.data;
}