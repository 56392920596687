import {BrowserRouter,Routes,Route} from "react-router-dom";
import { Mypage } from "./pages/Mypage";
import { Signup } from "./pages/Signup";
import { Home } from './pages/Home';
import { Share } from './pages/Share';
import { Admin } from './pages/Admin';
import { Excel } from './pages/Excel';

function App() {
  return (
    <BrowserRouter >
    <Routes>
        <Route path='/' element={<Home/>} />
        <Route path='/signup' element={<Signup/>} />
        <Route path='/mypage' element={<Mypage/>} />
        <Route path='/share' element={<Share/>} />
        <Route path='/admin' element={<Admin/>} />
        <Route path='/excel' element={<Excel/>} />
    </Routes>
    </BrowserRouter>
  );
}

export default App;
