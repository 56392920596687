import { getAuthAxios } from './authAxios';

export const getMembers = async (page, search = '') => {
    const access = localStorage.getItem('access');
    const authAxios = getAuthAxios(`Bearer ${access}`);

    try {
        // 검색어가 있으면 쿼리 파라미터에 추가
        const response = await authAxios.get(`/member/list?page=${page}&search=${encodeURIComponent(search)}`);
        return response.data;
    } catch (error) {
        console.error('Failed to fetch members data:', error);
        console.error('Axios error details:', error.response);
        throw error; // Re-throw to handle it in the component
    }
}
