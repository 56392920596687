import axios from 'axios';

export const login = async (id, pw) => {
    try {
        const response = await axios.post(`${process.env.REACT_APP_PUBLIC_URL}:3001/api/login`, { username: id, password: pw });
        return response.data.data;
    } catch (error) {
        console.error('Login error:', error.response || error);
        throw error;
    }
}